export const ITEM_MODE_INVENTORY = "Inventory";

export const RECEIVING_MODE_SOURCE_TYPE = "Receiving";

export const MANIFEST_ITEM_DOWNLOAD_TYPES = [
  {
    value: "ManifestWithPhotos",
    label: "Manifest with Photos",
  },
  {
    value: "ManifestOnly",
    label: "Manifest Only",
  },
  {
    value: "FeaturedOnly",
    label: "Featured Items",
  },
];
export const PROCESSING_FROM_VALUE_MAP = {
  Manifest: "Manifest",
  Receiving: "Receiving",
  Warehouse: "Warehouse",
  FullInfoNewAddition: "Inventory Item Addition",
  Restock: "Restock",
};

export const DELIVERY_LOAD_COST_ALLOCATION_TYPE = [
  {
    value: "DynamicRetailPriceCostAllocation",
    label: "Dynamic Retail Price Cost Allocation",
  },
  {
    value: "ItemCountCostAllocation",
    label: "Item Count Cost Allocation",
  },
];

export const PALLET_TYPES = [
  {
    value: "IncomingPallet",
    label: "Incoming Pallet",
  },
  {
    value: "NewPallet",
    label: "New Pallet",
  },
];

export const DELIVERY_LOAD_STATUS = [
  {
    value: "AwaitingArrival",
    label: "Awaiting Arrival",
  },
  {
    value: "Unloaded",
    label: "Unloaded",
  },
];
